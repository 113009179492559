<template>
  <div class="kt-report-edit">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="navigateBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("cytologyReport.pageTitle") }}
      </h2>
      <!-- page loader -->
      <b-spinner
        v-show="isLoading"
        class="align-middle"
        :class="'kt-spinner--' + $systemSettings.theme"
        style="margin-left: 10px;"
      ></b-spinner>
    </div>

    <!-- page body -->
    <div
      class="kt-page-body"
      :class="{'kt-page-body--loading': isLoading}"
    >
      <ReportFileDetails
        :fileDataProp="fileData"
        :hpvResultsProp="hpvResults"
        :openAddComplementaryProp="openAddComplementary"
        @openedAddComplementary="openAddComplementary = false;"
        @updateGroupOfFiles="fileData.groupOfFiles = $event.groupOfFiles; groupOfFiles = $event.groupOfFiles; complementaryFiles = $event.complementaryFiles;"
        @refreshCancelledFile="refreshCancelledFile"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />

      <!-- versions navigation -->
      <div
        v-show="previousCytologyReports.length"
        class="kt-validated-warning kt-badge kt-badge--light-gray kt-badge--lg mt-2"
      >
        <b-icon
          icon="clock-history"
          class="kt-badge__icon"
        ></b-icon>
        <span
          class="kt-badge__text font-weight-semi-bold"
          style="margin-right: 11px;"
        >{{ $t("medicalReportEdit.versioning.versionsSectionTitle") }}</span>
        <!-- versions buttons -->
        <b-button-group
          size="sm"
          class="kt-btn-group kt-btn-group--white-blue"
        >
          <b-button
            v-for="cytologyReport in previousCytologyReports"
            :key="cytologyReport.id"
            class="kt-btn-group__btn"
            :class="{'kt-btn-group__btn--active': displayedVersionId === cytologyReport.id}"
            :style="'color: #a8001b !important; border-color: #a8001b !important;'"
            variant="light"
            @click="displayedVersionId = cytologyReport.id"
          >
            <!-- arrow-counterclockwise rotate="270"-->
            <b-icon icon="file-earmark"></b-icon> {{ cytologyReport.content.medicalValidationTimestamp ? $d(new Date(cytologyReport.content.medicalValidationTimestamp), "date") : '' }}
          </b-button>
          <!-- current version button -->
          <b-button
            class="kt-btn-group__btn"
            :class="{'kt-btn-group__btn--active': displayedVersionId === null}"
            :style="
              !fileData ? 'color: #484848 !important; border-color: #5f5f5f !important;'
              : fileData.medicalStatus.name === 'validatedTechnically' ? 'border-color: #185d86 !important; color: rgb(6, 72, 121) !important;'
                : fileData.medicalStatus.name === 'validatedMedically' ? 'color: rgb(17, 84, 28) !important; border-color: rgb(27, 111, 41) !important;'
                  : 'color: #484848 !important; border-color: #5f5f5f !important;'
            "
            variant="light"
            @click="displayedVersionId = null"
          >
            <b-icon icon="file-earmark"></b-icon> {{ $t("medicalReportEdit.versioning.currentVersionBackButton") }}
          </b-button>
        </b-button-group>
      </div>

      <!-- REPORT 3 COLOMNS -->
      <b-row
        v-show="displayedVersionId === null && !validatedMedically && !validatedTechnically && !cancelled"
        class="kt-row__padding-sm mt-2"
      >
        <!-- LEFT COLUMN : report context -->
        <b-col class="kt-report-edit__fixed-col">
          <div class="kt-report-context">
            <!-- cytotechnician name -->
            <!-- <div class="text-15 mb-2">
              <span
                class="font-weight-bold"
                style="color: #373b3f;"
              >{{ "Cytotech. :" + "" }}
              </span>{{ (cytotechnician ? cytotechnician.firstName + " " + cytotechnician.lastName : $t("general.emptyWithHyphen")) }}
            </div> -->
            <!-- pathologist name -->
            <h2 class="kt-report-edit__title">
              {{ $t("medicalReportEdit.pathologist") }}
            </h2>
            <InputSearch
              searchedTableProp="pathologists"
              searchedFieldProp="user.lastName"
              :searchedLaboratoriesProp="laboratoryId ? [laboratoryId] : []"
              :returnFieldsArrayProp="['id', 'user.lastName', 'user.firstName', 'user.isActive', 'code']"
              suggestionsKeyProp="id"

              :valueProp="pathologist"
              :displayedValueProp="suggestionFormatPathologists(pathologist)"
              :suggestionFormatProp="suggestionFormatPathologists"

              :stateProp="pathologistStateDisplay"
              :invalidFeedbackProp="pathologistInvalidFeedback"

              @onUpdateParent="pathologist = $event; edited = true"
              @onActivateValidation="pathologistValidation = true"
              @onDisableValidation="pathologistValidation = false"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            ></InputSearch>

            <!-- divider and title -->
            <div class="kt-report-context__divider"></div>
            <h2 class="kt-report-edit__title">
              {{ $t("medicalReportEdit.clinicalInformation") }}
            </h2>

            <!-- screening -->
            <span
              class="kt-badge kt-badge--light-gray"
            >
              {{ $t("cytologyReport.screening.screening") + ' :' }}
              <strong>{{ screening ? $t("cytologyReport.screening." + screening) : $t("cytologyReport.screening.empty") }}</strong>
            </span>

            <!-- hormonal status -->
            <div class="kt-listed-input">
              <label
                class="mr-2 kt-report-edit__label"
                for="hormonalStatus-radio"
              >{{ $t("cytologyReport.hormonalStatus.hormonalStatus") }}</label>
              <b-button
                id="hormonalStatus-radio"
                v-b-modal="'editHormonalStatusModal'"
                size="sm"
                pill
                :variant="'outline-' + $systemSettings.theme"
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.hormonalStatus"
                class="kt-listed-input__list"
              >
                <li
                  class="kt-listed-input__item"
                >
                  {{ form.hormonalStatus ? $t("cytologyReport.hormonalStatus." + form.hormonalStatus) : '' }}
                </li>
              </ul>
            </div>

            <!-- information -->
            <div class="kt-listed-input">
              <label
                class="mr-2 kt-report-edit__label"
                for="edit-information"
              >{{ $t("cytologyReport.information.information") }}</label>
              <b-button
                id="edit-information"
                v-b-modal="'editInformationModal'"
                size="sm"
                pill
                :variant="'outline-' + $systemSettings.theme"
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.information.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="info in form.information"
                  :key="info.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.information." + info) }}
                </li>
              </ul>
            </div>

            <div class="kt-report-context__divider"></div>

            <!-- Send the report to the patient -->
            <b-form-checkbox
              id="js-sendReportToPatient"
              v-model="form.sendReportToPatient"
              :value="true"
              :unchecked-value="false"
              @change="sendReportToPatientHasChanged = false"
            >
              {{ $t("medicalReportEdit.sendReportToPatient") }}
            </b-form-checkbox>
            <div v-show="sendReportToPatientHasChanged">
              <span class="kt-badge kt-badge--red">
                <span class="kt-badge__text">{{ $t("medicalReportEdit.sendReportToPatientHasChanged") }}</span>
              </span>
            </div>
          </div>
        </b-col>

        <!-- MIDDLE COLUMN : results -->
        <b-col>
          <div class="kt-report-results">
            <!-- smear & layering -->
            <div class="kt-report-results__radio-group mb-3">
              <span class="kt-badge kt-badge--light-gray">
                {{ $t("cytologyReport.smear.smear") + ' :' }}
                <strong>{{ smear ? $t("cytologyReport.smear." + smear) : $t("cytologyReport.smear.empty") }}</strong>
              </span>
              <span style="display: inline-block; border-left: 1px solid #dee0e2; height: 1rem; vertical-align: middle; margin: 0 4px"></span>
              <span class="kt-badge kt-badge--light-gray">
                {{ $t("cytologyReport.layering.layering") + ' :' }}
                <strong>{{ layering ? $t("cytologyReport.layering." + layering) : $t("cytologyReport.layering.empty") }}</strong>
              </span>
            </div>

            <!-- select - satisfactory -->
            <b-form-select
              v-model="form.satisfactory"
              :disabled="validatedMedically || !isCytology"
              :options="satisfactoryChoices"
              class="mb-2"
              value-field="id"
              text-field="name"
              @change="onSatisfactoryChange"
            ></b-form-select>
            <!-- select - quality -->
            <b-form-select
              v-show="form.satisfactory === 'satisfactory'"
              v-model="form.quality"
              :disabled="validatedMedically || !isCytology"
              :options="qualityChoices"
              class="mb-3"
              value-field="id"
              text-field="name"
            ></b-form-select>
            <!-- Select - sub qualities  -->
            <b-form-checkbox-group
              v-show="form.satisfactory !== 'satisfactory'"
              v-model="subquality"
              class="mb-3 pt-1 pb-2"
              :disabled="validatedMedically || !isCytology"
              :options="subqualityChoices"
              @input="edited = true"
            ></b-form-checkbox-group>

            <!-- radio - result -->
            <b-form-group
              :disabled="validatedMedically || !isCytology"
              class="kt-report-evaluation mb-0"
              :class="form.result !== '' ? 'kt-report-evaluation--checked' : 'kt-report-evaluation--unchecked'"
              :state="resultStateDisplay"
            >
              <div class="kt-report-evaluation__wrapper">
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--1">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'nilm' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="nilm"
                    @click="onResultClick('nilm')"
                  >
                    {{ $t("cytologyReport.result.nilm") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--2">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'ascus' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="ascus"
                    @click="onResultClick('ascus')"
                  >
                    {{ $t("cytologyReport.result.ascus") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--2">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'asch' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="asch"
                    @click="onResultClick('asch')"
                  >
                    {{ $t("cytologyReport.result.asch") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'lsil' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="lsil"
                    @click="onResultClick('lsil')"
                  >
                    {{ $t("cytologyReport.result.lsil") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'agc' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="agc"
                    @click="onResultClick('agc')"
                  >
                    {{ $t("cytologyReport.result.agc") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'hsil' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="hsil"
                    @click="onResultClick('hsil')"
                  >
                    {{ $t("cytologyReport.result.hsil") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--4">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'ais' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="ais"
                    @click="onResultClick('ais')"
                  >
                    {{ $t("cytologyReport.result.ais") }}
                  </b-button>
                </div>
                <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--4">
                  <b-button
                    v-model="form.result"
                    :class="form.result === 'scc' ? 'kt-report-evaluation__active' : ''"
                    :disabled="form.satisfactory !== 'satisfactory'"
                    value="scc"
                    @click="onResultClick('scc')"
                  >
                    {{ $t("cytologyReport.result.scc") }}
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <div
              v-show="resultStateDisplay === false && resultInvalidFeedback"
              class="text-14 text-center"
              style="color: #dc3545; margin-top: 12px;"
            >
              <b-icon
                icon="exclamation-circle-fill"
                class="text-16 mr-1"
              ></b-icon>
              {{ resultInvalidFeedback }}
            </div>
          </div>
        </b-col>

        <!-- RIGHT COLUMN -->
        <b-col class="kt-report-edit__fixed-col">
          <div class="kt-report-right">
            <!-- COMPLEMENTS  -->
            <h2 class="kt-report-edit__title">
              {{ $t("medicalReportEdit.complements") }}
            </h2>

            <!-- pathogens -->
            <div class="kt-listed-input mt-0">
              <label
                class="kt-report-edit__label"
                for="report-pathogens-input"
              >{{ $t("cytologyReport.pathogens.pathogens") }}</label>
              <b-button
                id="report-pathogens-input"
                v-b-modal="'editPathogensModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.pathogens.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="pathogen in form.pathogens"
                  :key="pathogen.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.pathogens." + pathogen) }}
                </li>
              </ul>
            </div>

            <!-- hormonal -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-hormonal-input"
              >{{ $t("cytologyReport.hormonal.hormonal") }}</label>
              <b-button
                id="report-hormonal-input"
                v-b-modal="'editHormonalModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.hormonal.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="hormonalItem in form.hormonal"
                  :key="hormonalItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.hormonal." + hormonalItem) }}
                </li>
              </ul>
            </div>

            <!-- reaction -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-reaction-input"
              >{{ $t("cytologyReport.reaction.reaction") }}</label>
              <b-button
                id="report-reaction-input"
                v-b-modal="'editReactionModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.reaction.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="reactionItem in form.reaction"
                  :key="reactionItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.reaction." + reactionItem) }}
                </li>
              </ul>
            </div>

            <!-- reorganisation -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-reorganisation-input"
              >{{ $t("cytologyReport.reorganisation.reorganisation") }}</label>
              <b-button
                id="report-reorganisation-input"
                v-b-modal="'editReorganisationModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.reorganisation.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="reorganisationItem in form.reorganisation"
                  :key="reorganisationItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.reorganisation." + reorganisationItem) }}
                </li>
              </ul>
            </div>

            <!-- complementaryTechniques -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-complementaryTechniques-input"
              >{{ $t("cytologyReport.complementaryTechniques.complementaryTechniques") }}</label>
              <b-button
                id="report-complementaryTechniques-input"
                v-b-modal="'editComplementaryTechniquesModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.complementaryTechniques.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="complementaryTechItem in form.complementaryTechniques"
                  :key="complementaryTechItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.complementaryTechniques." + complementaryTechItem) }}
                </li>
              </ul>
            </div>

            <!-- control -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-control-input"
              >{{ $t("cytologyReport.control.control") }}</label>
              <b-button
                id="report-control-input"
                v-b-modal="'editControlModal'"
                :disabled="validatedMedically || !isCytology"
                class="ml-2"
                :variant="validatedMedically || !isCytology ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.control.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="controlItem in form.control"
                  :key="controlItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.control." + controlItem) }}
                </li>
              </ul>
            </div>

            <!-- recommendations -->
            <div class="kt-listed-input">
              <label
                class="kt-report-edit__label"
                for="report-recommendations-input"
              >{{ $t("cytologyReport.recommendations.recommendations") }}</label>
              <b-button
                id="report-recommendations-input"
                v-b-modal="'editRecommendationsModal'"
                :disabled="isValidatedOrHasResult"
                class="ml-2"
                :variant="isValidatedOrHasResult ? 'outline-secondary' : 'outline-' + $systemSettings.theme"
                size="sm"
                pill
              >
                {{ $t("general.add") }}
              </b-button>
              <ul
                v-show="form.recommendations.length !== 0"
                class="kt-listed-input__list"
              >
                <li
                  v-for="recommendationsItem in form.recommendations"
                  :key="recommendationsItem.id"
                  class="kt-listed-input__item"
                >
                  {{ $t("cytologyReport.recommendations." + recommendationsItem) }}
                </li>
              </ul>
              <!-- free text -->
              <div :class="form.recommendations.length !== 0 ? 'mt-n2' : ''">
                <b-button
                  v-show="!displayOtherRecommendation"
                  :disabled="isValidatedOrHasResult"
                  class="mt-n2 ml-n1 kt-btn-transparent pl-0"
                  :class="!isValidatedOrHasResult ? 'text-' + $systemSettings.theme : 'bg-white'"
                  variant="light"
                  pill
                  @click="openRecommendation"
                >
                  <b-icon icon="plus" />
                  {{ $t("cytologyReport.recommendations.otherRecommendation") }}
                </b-button>
                <TextareaAutosize
                  v-if="displayOtherRecommendation"
                  ref="otherRecommendation"
                  v-model="form.otherRecommendation"
                  class="mb-1"
                  :class="form.recommendations.length !== 0 ? '' : ' mt-1'"
                  :maxlength="256"
                  autocomplete="off"
                  :maxHeight="126"
                  :submitFunction="() => {
                    if (canValidate) {
                      onValidate(true);
                    }
                  }"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- REPORT VIEW (WHEN VALIDATED) -->
      <div
        v-if="displayedVersionId === null && (validatedMedically || validatedTechnically || cancelled)"
      >
        <CytologyReportView
          v-if="cytologyReportData"
          :cytologyReportDataProp="cytologyReportData"
          :showFileDetailsProp="false"
          @newVersionCreated="refreshReportByFileId(fileId, true)"
          @refreshCytologyReport="refreshCytologyReports"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </div>

      <!-- PREVIOUS REPORTS VIEW -->
      <div
        v-show="previousCytologyReports.length > 0"
      >
        <div
          v-for="cytologyReport in previousCytologyReports"
          :key="cytologyReport.id"
        >
          <CytologyReportView
            v-show="displayedVersionId === cytologyReport.id"
            :cytologyReportDataProp="cytologyReport"
            :showFileDetailsProp="false"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </div>
      </div>

      <!-- report bottom action bar -->
      <div
        v-show="!cancelled && displayedVersionId === null"
        class="kt-report-actions"
      >
        <div class="kt-report-actions__wrapper">
          <!-- Save -->
          <b-button
            v-show="!validatedMedically"
            class="kt-report-actions__btn"
            variant="outline-secondary"
            @click="onSave"
          >
            {{ saveButtonText }}
          </b-button>
          <!-- Validate -->
          <b-button
            v-show="canValidate"
            class="kt-report-actions__btn"
            :variant="$systemSettings.theme"
            @click="onValidate(true)"
          >
            {{ isPathologist || isAdmin ? $t("medicalReportEdit.actions.validateMedically") : $t("medicalReportEdit.actions.validateTechnically") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- Modal - edit Hormonal Status -->
    <b-modal
      id="editHormonalStatusModal"
      hide-footer
    >
      <MultipleChoice
        isRadioProp
        :editedValuesProp="form.hormonalStatus"
        :optionsProp="hormonalStatusOptions"
        :saveEventNameProp="'hormonalStatusEdited'"
        @hormonalStatusEdited="
          form.hormonalStatus = $event;
          $bvModal.hide('editHormonalStatusModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Information -->
    <b-modal
      id="editInformationModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.information"
        :optionsProp="informationOptions"
        :saveEventNameProp="'informationEdited'"
        @informationEdited="
          form.information = $event;
          $bvModal.hide('editInformationModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Pathogens -->
    <b-modal
      id="editPathogensModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.pathogens"
        :optionsProp="pathogensOptions"
        :saveEventNameProp="'pathogensEdited'"
        @pathogensEdited="
          form.pathogens = $event;
          $bvModal.hide('editPathogensModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Hormonal -->
    <b-modal
      id="editHormonalModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.hormonal"
        :optionsProp="hormonalOptions"
        :saveEventNameProp="'hormonalEdited'"
        @hormonalEdited="
          form.hormonal = $event;
          $bvModal.hide('editHormonalModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Reaction -->
    <b-modal
      id="editReactionModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.reaction"
        :optionsProp="reactionOptions"
        :saveEventNameProp="'reactionEdited'"
        @reactionEdited="
          form.reaction = $event;
          $bvModal.hide('editReactionModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Reorganisation -->
    <b-modal
      id="editReorganisationModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.reorganisation"
        :optionsProp="reorganisationOptions"
        :saveEventNameProp="'reorganisationEdited'"
        @reorganisationEdited="
          form.reorganisation = $event;
          $bvModal.hide('editReorganisationModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Complementary Techniques -->
    <b-modal
      id="editComplementaryTechniquesModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.complementaryTechniques"
        :optionsProp="complementaryTechniquesOptions"
        :saveEventNameProp="'complementaryTechniquesEdited'"
        @complementaryTechniquesEdited="
          form.complementaryTechniques = $event;
          $bvModal.hide('editComplementaryTechniquesModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Control -->
    <b-modal
      id="editControlModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.control"
        :optionsProp="controlOptions"
        :saveEventNameProp="'controlEdited'"
        @controlEdited="
          form.control = $event;
          $bvModal.hide('editControlModal');
        "
      />
    </b-modal>
    <!-- Modal - edit Recommendations -->
    <b-modal
      id="editRecommendationsModal"
      hide-footer
    >
      <MultipleChoice
        :editedValuesProp="form.recommendations"
        :optionsProp="recommendationsOptions"
        :saveEventNameProp="'recommendationsEdited'"
        @recommendationsEdited="
          form.recommendations = $event;
          $bvModal.hide('editRecommendationsModal');
        "
      />
    </b-modal>

    <!-- Modal - validation confirmation -->
    <b-modal
      id="validationConfirmationModal"
      :title="$t('medicalReportEdit.validationConfirmation.modalTitle')"
      hide-footer
    >
      <div class="line-height-md">
        {{ $t('medicalReportEdit.validationConfirmation.modalText') }}
      </div>
      <div
        v-show="!!complementaryCount"
        class="line-height-md mt-2 text-14"
        :class="'text-' + $systemSettings.theme"
        v-html="validationConfirmationText"
      ></div>

      <div class="mt-2">
        <b-button
          class="mb-2 mt-2"
          variant="outline-secondary"
          @click="() => {
            openAddComplementary = true;
            $bvModal.hide('validationConfirmationModal');
          }"
        >
          {{ $t('medicalReportEdit.validationConfirmation.addComplementary') }}
        </b-button>
        <b-button
          class="mb-2 mt-2 ml-3"
          :variant="$systemSettings.theme"
          @click="onValidate(false)"
        >
          {{ $t('medicalReportEdit.validationConfirmation.validate') }}
        </b-button>
      </div>
    </b-modal>
    <!-- Modal - report alreadyCreated Modal  -->
    <b-modal
      :id="'alreadyCreatedModal-' + _uid"
      size="sm-larger"
      hide-footer
      hide-header
      @shown="() => {
        $refs.alreadyCreatedButton.focus()
      }"
    >
      <div class="text-center py-1">
        <h4
          style="font-size: 18px;
          letter-spacing: -0.03px;
          margin-bottom: 12px;"
        >
          <b-icon
            icon="exclamation-triangle"
            style="margin-right: 6px; color: #a8001b !important;"
          ></b-icon>
          {{ $t('medicalReportEdit.notifications.alreadyCreatedTitle') }}
        </h4>
        <b-button
          ref="alreadyCreatedButton"
          :variant="$systemSettings.theme"
          size="sm"
          @click="refreshAndCloseWarning"
        >
          <b-icon icon="eye"></b-icon>
          {{ $t('medicalReportEdit.notifications.alreadyCreatedBtn') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// components
import ReportFileDetails from "@/views/Diagnosis/ReportFileDetails";
import CytologyReportView from "@/views/Diagnosis/CytologyReports/CytologyReportView";
import InputSearch from "@shared/views/Helpers/InputSearch";
import MultipleChoice from "@shared/views/Helpers/MultipleChoice.vue";
import TextareaAutosize from "@shared/views/Helpers/TextareaAutosize";
// services
import commonServices from "@shared/services/API/commonServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
import filesServices from "@/services/API/filesServices";
// helpers
import cytologyReportMixinOptions from "@/views/Diagnosis/CytologyReports/cytologyReportMixinOptions";
import recommendationsTable from "@/views/Diagnosis/recommendationsTable";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: {
    ReportFileDetails,
    CytologyReportView,
    InputSearch,
    MultipleChoice,
    TextareaAutosize
  },
  mixins: [cytologyReportMixinOptions, userRights, error, date],
  props: {
    fileIdProp: {
      type: Number,
      default: null
    },
    cancelRouteProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general variables
      isLoading: true,
      cytologyReportData: null,
      cytologyReportId: null,
      // versioning variables
      previousCytologyReports: [],
      displayedVersionId: null,

      edited: false,
      editMode: false,
      previousSendReportToPatient: false,
      isDownloading: false,
      openAddComplementary: false,

      // file info
      fileData: null,
      fileId: null,
      parentFileId: null,
      complementaryFiles: [],
      groupOfFiles: [],

      validatedTechnically: false,
      validatedMedically: false,
      cancelled: false,

      laboratoryId: null,
      age: 0,
      isHpv: false,
      isCytology: false,
      hpvResults: [],
      // radio-button values
      screening: "",
      smear: "",
      layering: "",

      // base variables
      form: {
        hormonalStatus: "",
        information: [],
        sendReportToPatient: false,
        satisfactory: "satisfactory",
        result: "",
        quality: "satisfactoryWithCells",
        pathogens: [],
        hormonal: [],
        reaction: [],
        reorganisation: [],
        complementaryTechniques: [],
        control: [],
        recommendations: [],
        otherRecommendation: ""
      },
      displayOtherRecommendation: false,
      subquality: [],
      pathologist: null,
      cytotechnician: null,
      sendReportToPatientHasChanged: false,

      // validation variables
      pathologistValidation: false,
      resultValidation: false
    };
  },
  computed: {
    isValidatedOrHasResult: function() {
      return this.validatedMedically || (this.isHpv && this.hpvResults.length === 0) || (this.isCytology && this.form.satisfactory === "satisfactory" && !this.form.result);
    },
    isAdmin: function() {
      return this.isRole("userAdmin") || this.isRole("system");
    },
    isPathologist: function() {
      return this.isRole("pathologist");
    },
    isCytotechnician: function() {
      return this.isRole("cytotechnician");
    },

    saveButtonText: function() {
      if (this.validatedTechnically) {
        return this.$t("medicalReportEdit.actions.backToCreated");
      } else {
        return this.$t("medicalReportEdit.actions.save");
      }
    },
    canValidate: function() {
      return (
        (this.isEnabled(["roleSpecific", "pathologist"]) && !this.validatedMedically) ||
        (this.isEnabled(["roleSpecific", "cytotechnician"]) && !this.validatedTechnically && !this.validatedMedically)
      );
    },

    // form validation
    pathologistState: function() {
      return this.pathologist && this.pathologist.id && this.pathologist.id > 0 ? null : false;
    },
    pathologistStateDisplay: function() {
      if (!this.pathologistValidation) {
        return null;
      } else {
        return this.pathologistState;
      }
    },
    pathologistInvalidFeedback: function() {
      if (this.pathologistStateDisplay === false) {
        return this.$t("validationRules.required");
      } else {
        return "";
      }
    },
    resultState: function() {
      if (this.isCytology) {
        if (this.form.satisfactory === "satisfactory") {
          return this.form.result ? null : false;
        } else {
          return !this.form.result ? null : false;
        }
      } else {
        return null;
      }
    },
    resultStateDisplay: function() {
      if (!this.resultValidation) {
        return null;
      } else {
        return this.resultState;
      }
    },
    resultInvalidFeedback: function() {
      if (this.resultStateDisplay === null) return "";

      if (this.isCytology) {
        if (this.form.satisfactory === "satisfactory") {
          if (!this.form.result) return this.$t("medicalReportEdit.validations.emptyResult");
        } else {
          if (this.form.result) return this.$t("medicalReportEdit.validations.resultMustBeEmpty");
        }
      }
      return "";
    },
    // formatted variables
    validationConfirmationText: function() {
      return this.$tc("medicalReportEdit.validationConfirmation.complementaryCount", this.complementaryCount, { count: this.complementaryCount });
    },
    complementaryCount: function() {
      return this.groupOfFiles.filter((file) => { return Boolean(file.parentFileId); }).length;
    }
  },
  watch: {
    // when the result is negative, auto select sendReportToPatient
    "form.result": {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updateSendReportToPatient();
        }
      },
      immediate: false
    },
    // when quality is satisfactory, empty sub quality option and change the options list
    "form.satisfactory": {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.subquality = [];
          if (this.form.satisfactory === "satisfactory") {
            this.qualityChoices = this.qualitySatisfactoryChoices;
            this.form.quality = "satisfactoryWithCells";
          } else {
            this.qualityChoices = this.qualityNotSatisfactoryChoices;
            this.form.quality = "notSatisfactory";
            this.form.recommendations = [];
            this.form.otherRecommendation = "";
            this.displayOtherRecommendation = false;
          }
          this.updateSendReportToPatient();
        }
      },
      immediate: false,
      deep: false
    },

    // on form change, edited = true
    form: {
      handler: function() {
        this.edited = true;
      },
      deep: true,
      immediate: false
    }
  },
  async mounted() {
    this.qualityChoices = this.qualitySatisfactoryChoices;
    await this.importCytologyReport();
  },
  methods: {
    async refreshAndCloseWarning() {
      await this.refreshCytologyReports();
      this.$bvModal.hide("alreadyCreatedModal-" + this._uid);
    },
    async refreshCytologyReports() {
      this.clearCytologyReportData();
      await this.importCytologyReport();
    },
    clearCytologyReportData() {
      this.cytologyReportData = null;
      this.cytologyReportId = null;
      // versioning variables
      this.previousCytologyReports = [];
      this.displayedVersionId = null;

      this.edited = false;
      this.editMode = false;
      this.previousSendReportToPatient = false;
      this.isDownloading = false;
      this.openAddComplementary = false;

      // file info
      this.fileData = null;
      this.fileId = null;
      this.parentFileId = null;
      this.complementaryFiles = [];
      this.groupOfFiles = [];

      this.validatedTechnically = false;
      this.validatedMedically = false;
      this.cancelled = false;

      this.laboratoryId = null;
      this.age = 0;
      this.isHpv = false;
      this.isCytology = false;
      this.hpvResults = [];
      // radio-button values
      this.screening = "";
      this.smear = "";
      this.layering = "";

      // base variables
      this.form = {
        hormonalStatus: "",
        information: [],
        sendReportToPatient: false,
        satisfactory: "satisfactory",
        result: "",
        quality: "satisfactoryWithCells",
        pathogens: [],
        hormonal: [],
        reaction: [],
        reorganisation: [],
        complementaryTechniques: [],
        control: [],
        recommendations: [],
        otherRecommendation: ""
      };
      this.displayOtherRecommendation = false;
      this.subquality = [];
      this.pathologist = null;
      this.cytotechnician = null;
      this.sendReportToPatientHasChanged = false;

      // validation variables
      this.pathologistValidation = false;
      this.resultValidation = false;
    },
    async importCytologyReport() {
      try {
        // import report
        const resReportImport = await this.refreshReportByFileId(this.fileIdProp, false);

        // if no report, create new from file
        if (!resReportImport.imported) {
          await this.createNewReportFromFileId(resReportImport.awaitingHpvResults, resReportImport.file);
        }

        if (!resReportImport.imported) {
          this.updateSendReportToPatient();
        } else {
          if (!this.validatedTechnically && !this.validatedMedically) {
            // update send report to patient
            if (this.isHpv) {
              this.updateSendReportToPatient();
              if (this.previousSendReportToPatient !== this.form.sendReportToPatient) {
                this.sendReportToPatientHasChanged = true;
                this.edited = true;
              }
            }
            // update automatic recommendations if none
            if (this.form.recommendations.length === 0) {
              this.updateRecommendations();
              if (this.form.recommendations.length > 0) {
                this.edited = true;
              }
            }

            // check if information are still valid
            if (!this.isCytology) {
              this.subquality = [];
              this.form.satisfactory = "satisfactory";
              this.form.quality = "";
              this.form.result = "";
            } else {
              if (this.form.quality === "") this.form.quality = "satisfactoryWithCells";
            }
          }
        }

        this.isLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    openRecommendation() {
      this.displayOtherRecommendation = true;
      this.$nextTick(() => {
        if (this.$refs.otherRecommendation.$el) {
          this.$refs.otherRecommendation.$el.focus();
        }
      });
    },

    updateSendReportToPatient() {
      this.sendReportToPatientHasChanged = false;

      if (this.validatedTechnically || this.validatedMedically || (!this.isHpv && this.editMode)) {
        return false;
      }

      let isCytologyPathological = null;
      if (this.isCytology) {
        if (this.form.result === "nilm") {
          isCytologyPathological = false;
        } else if (this.form.result === "") {
          // Do nothing
        } else {
          isCytologyPathological = true;
        }
      }
      let isHpvPathological = null;
      if (this.isHpv) {
        if (this.hpvResults.length > 0) {
          if (this.hpvResults[this.hpvResults.length - 1].content === "-") {
            isHpvPathological = false;
          } else if (this.hpvResults[this.hpvResults.length - 1].content === "INDETERMINATE") {
            // Do nothing
          } else {
            isHpvPathological = true;
          }
        }
      }
      let isPathological = null;
      if (this.isCytology && this.isHpv) {
        if (isCytologyPathological === false && isHpvPathological === false) {
          isPathological = false;
        } else if (isCytologyPathological || isHpvPathological) {
          isPathological = true;
        } else {
          // Do nothing
        }
      } else if (this.isCytology) {
        isPathological = isCytologyPathological;
      } else if (this.isHpv) {
        isPathological = isHpvPathological;
      }

      this.form.sendReportToPatient = isPathological === false || isPathological === null;
    },

    async createNewReportFromFileId(awaitingHpvResults, file) {
      this.isLoading = true;

      if (file.id) {
        await this.updateFileInfo(file);
        this.validatedTechnically = false;
        this.validatedMedically = false;
        this.cancelled = file.medicalStatus.name === "cancelled";
        if (this.cancelled) {
          this.cytologyReportData = {
            id: null,
            cytotechnician: this.$userSettings.user,
            cytotechnicianId: this.$userSettings.user.id,
            pathologistId: file.pathologist.id,
            pathologist: file.pathologist,
            fileId: file.id,
            content: {
              hormonalStatus: "",
              information: [],
              sendReportToPatient: true,
              satisfactory: "satisfactory",
              result: "",
              quality: "satisfactoryWithCells",
              pathogens: [],
              hormonal: [],
              reaction: [],
              reorganisation: [],
              complementaryTechniques: [],
              control: [],
              recommendations: [],
              pathologistId: file.pathologist.id,
              technicalValidationTimestamp: "",
              medicalValidationTimestamp: ""
            },
            parentCytologyReportId: null,
            isSuperseded: false,
            isArchived: null,
            isSentByPost: null,
            isSentElectronically: null,
            hpvResults: [],
            file: file,
            parentCytologyReport: null,
            cytologyReport: null
          };
        }
        this.updateRecommendations();
        this.pathologist = file.pathologist;
        this.cytotechnician = this.$userSettings.user;
        this.edited = true;
        // if complementary file : get the parent clinical information
        if (file.parentFileId) {
          const res = await cytologyReportsServices.getClinicalInformation(file.parentFileId);
          if (res.data) {
            this.form.hormonalStatus = res.data.hormonalStatus;
            this.form.information = res.data.information;
            this.form.sendReportToPatient = res.data.sendReportToPatient;
          }
        }
        // assign awaitingHpvResults
        this.refreshHpvResults(awaitingHpvResults || []);

        this.isLoading = false;
      } else {
        // no file found
        this.$emit("alert", "danger", {
          title: this.$t("medicalReportEdit.notifications.unknownAccessNumberTitle"),
          message: this.$t("medicalReportEdit.notifications.unknownAccessNumberText")
        });
      }
    },
    async refreshCancelledFile() {
      // import report
      const resReportImport = await this.refreshReportByFileId(this.fileIdProp, false);
      // if no report, create new from file
      if (!resReportImport.imported) {
        await this.createNewReportFromFileId(resReportImport.awaitingHpvResults, resReportImport.file);
      }
      this.isLoading = false;
    },
    async refreshReportByFileId(fileId, displayError = true) {
      try {
        this.isLoading = true;

        const resReports = await cytologyReportsServices.getAllByFileId(fileId); // available : cytologyReports / awaitingHpvResults / file
        const awaitingHpvResults = resReports.data.awaitingHpvResults || [];

        if (resReports.data.cytologyReports && resReports.data.cytologyReports.length > 0) {
          this.editMode = true;
          if (this.cytologyReportData !== null) {
            this.cytologyReportData = null;
            await this.$nextTick();
          }
          // file
          await this.updateFileInfo(resReports.data.file);
          // save latest report
          const lastCytologyReport = resReports.data.cytologyReports[resReports.data.cytologyReports.length - 1];
          lastCytologyReport.file = this.fileData;
          this.cytologyReportData = lastCytologyReport;
          this.cytologyReportId = this.cytologyReportData.id;
          this.validatedTechnically = this.fileData.medicalStatus.name === "validatedTechnically";
          this.validatedMedically = this.fileData.medicalStatus.name === "validatedMedically";
          this.cancelled = this.fileData.medicalStatus.name === "cancelled";
          this.refreshHpvResults((this.cytologyReportData.hpvResults || []).concat(awaitingHpvResults));
          // pathologist
          this.pathologist = this.cytologyReportData.pathologist;
          this.cytotechnician = this.cytologyReportData.cytotechnician;
          // report
          if (this.cytologyReportData.content) {
            this.updateReportInfo(this.cytologyReportData.content);
          }
          // save previous reports
          this.previousCytologyReports = [];
          for (let i = 0; i < resReports.data.cytologyReports.length - 1; i++) {
            resReports.data.cytologyReports[i].hpvResults = this.hpvResults;
            this.previousCytologyReports.push(resReports.data.cytologyReports[i]);
          }
          this.displayedVersionId = null;
          // reset edited
          this.$nextTick(() => { this.edited = false; });
        } else {
          this.cytologyReportData = null;
          this.cytologyReportId = null;
          this.hpvResults = [];
          if (displayError) {
            // no report found
            this.$emit("alert", "danger", {
              title: this.$t("cytologyReport.reportNotFound.title"),
              message: this.$t("cytologyReport.reportNotFound.text")
            });
          }
        }

        this.isLoading = false;

        return {
          imported: Boolean(resReports.data && resReports.data.cytologyReports && resReports.data.cytologyReports.length > 0), // boolean
          awaitingHpvResults: awaitingHpvResults, // array
          file: (resReports.data && resReports.data.file) ? resReports.data.file : null // object|null
        };
      } catch (err) {
        this.handleErrors(err);
      }
    },
    refreshHpvResults(hpvResults) {
      if (!this.isHpv || !hpvResults || !hpvResults.length) {
        this.hpvResults = [];
        return false;
      }
      this.hpvResults = hpvResults
        .map((v) => {
          if (v.dateObj) v.dateObj = new Date(v.timestamp);
          return v;
        })
        .sort((a, b) => a.dateObj - b.dateObj);
    },
    // unused for now
    async refreshReportOnly(id, displayError = true) {
      const resReport = await commonServices.get("cytologyReports", id);
      if (resReport.data.id) {
        // file
        const resFile = await commonServices.get("files", resReport.data.fileId);
        await this.updateFileInfo(resFile.data);
        // report
        resReport.data.file = this.fileDate;
        this.cytologyReportData = resReport.data;
        this.cytologyReportId = resReport.data.id;
        this.validatedTechnically = this.fileData.medicalStatus.name === "validatedTechnically";
        this.validatedMedically = this.fileData.medicalStatus.name === "validatedMedically";
        this.cancelled = this.fileData.medicalStatus.name === "cancelled";
        this.pathologist = this.cytologyReportData.pathologist;
        this.cytotechnician = this.cytologyReportData.cytotechnician;
        this.refreshHpvResults(resReport.hpvResults);
        // report
        if (this.cytologyReportData.content) {
          this.updateReportInfo(this.cytologyReportData.content);
        }
        // reset edited
        this.$nextTick(() => { this.edited = false; });
        return true;
      } else {
        if (displayError) {
          this.$emit("alert", "danger", {
            title: this.$t("diagnosis.unknownCytologyReport.title"),
            message: this.$t("diagnosis.unknownCytologyReport.text")
          });
        }
        return false;
      }
    },
    async updateFileInfo(file) {
      try {
        this.fileId = file.id;
        this.parentFileId = file.parentFileId;
        // groupOfFiles
        if (typeof file.childrenFiles === "undefined") file.childrenFiles = [];
        const complementaryFiles = file.childrenFiles.map((item) => {
          item.parentFileId = file.id;
          return item;
        });
        this.complementaryFiles = complementaryFiles;
        let groupOfFiles = [];
        if (this.parentFileId) {
          // current file is a complementary
          const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
          if (resLinkedFiles.data) groupOfFiles = resLinkedFiles.data;
          file.parentFile.accessNumber = file.accessNumber;
          groupOfFiles.unshift(file.parentFile);
        } else {
          // current file is the parent
          if (this.complementaryFiles && this.complementaryFiles.length) {
            groupOfFiles = this.complementaryFiles;
          }
          groupOfFiles.unshift({
            id: this.fileId,
            parentFileId: null,
            accessNumber: file.accessNumber,
            creationDate: file.creationDate,
            filingStatus: file.filingStatus || null,
            medicalStatus: file.medicalStatus,
            accountingStatus: file.accountingStatus || null
          });
        }
        this.groupOfFiles = groupOfFiles;
        file.groupOfFiles = groupOfFiles;

        this.fileData = file;

        this.laboratoryId = file.laboratoryId;
        this.age = this.getAgeYearFromBirthdate(file.patient.birthdate);

        if (file.containerGroups.length === 1 && file.containerGroups[0].containerGroupTasks.length === 1) {
          this.isHpv = file.containerGroups[0].containerGroupTasks[0].task.content.isHpv;
          this.isCytology = file.containerGroups[0].containerGroupTasks[0].task.content.isCytology;
          // radio-button values
          this.screening = file.containerGroups[0].containerGroupTasks[0].task.content.screening;
          this.smear = file.containerGroups[0].containerGroupTasks[0].task.content.smear;
          this.layering = file.containerGroups[0].containerGroupTasks[0].task.content.layering;
        } else {
          this.isHpv = false;
          this.isCytology = false;
          // radio-button values
          this.screening = "";
          this.smear = "";
          this.layering = "";
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateReportInfo(content) {
      this.form.hormonalStatus = content.hormonalStatus;
      this.form.information = content.information;
      this.form.sendReportToPatient = content.sendReportToPatient;
      this.previousSendReportToPatient = content.sendReportToPatient;
      this.form.satisfactory = content.satisfactory;
      this.form.result = content.result;
      this.form.quality = content.quality;
      this.$nextTick(() => {
        this.subquality = content.subquality;
      });
      this.form.cellModifications = content.cellModifications;
      this.form.pathogens = content.pathogens;
      this.form.hormonal = content.hormonal;
      this.form.reaction = content.reaction;
      this.form.reorganisation = content.reorganisation;
      this.form.complementaryTechniques = content.complementaryTechniques;
      this.form.control = content.control;
      this.form.recommendations = content.recommendations;
      if (content.otherRecommendation) {
        this.form.otherRecommendation = content.otherRecommendation;
        this.$nextTick(() => {
          this.displayOtherRecommendation = true;
        });
      } else {
        this.form.otherRecommendation = "";
        this.displayOtherRecommendation = false;
      }
    },

    updateRecommendations() {
      if (this.isCytology && !this.form.result && this.form.satisfactory === "satisfactory") {
        this.form.recommendations = [];
      } else if (this.isHpv && this.hpvResults.length === 0) {
        this.form.recommendations = [];
      } else {
        let hpvResultString = "";
        if (this.hpvResults.length) {
          hpvResultString = this.hpvResults[this.hpvResults.length - 1].content === "-" ? "hpvNegative" : "hpvPositive";
        }
        let cytologyResult = "";
        if (this.isCytology) {
          if (this.form.satisfactory === "satisfactory") {
            cytologyResult = this.form.result;
          } else { cytologyResult = "fns"; }
        } else {
          cytologyResult = "noCytology";
        }
        this.form.recommendations = recommendationsTable.getRecommendations(
          this.age <= 30,
          this.screening,
          this.isHpv,
          hpvResultString,
          cytologyResult
        );
      }
    },
    onSatisfactoryChange(value) {
      if (value !== "satisfactory") {
        this.form.result = "";
      }
      this.updateRecommendations();
    },
    // reset the result when click again
    onResultClick(value) {
      this.form.result = this.form.result === value ? "" : value;
      this.updateRecommendations();
    },
    // format pathologist name
    suggestionFormatPathologists(suggestion) {
      if (!suggestion) return "";
      let output = suggestion.user.firstName + " " + suggestion.user.lastName + " (" + suggestion.code + ")";
      if (suggestion.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },

    // validate form
    validateForm(technicalValidation = false, medicalValidation = false) {
      this.activateValidations(technicalValidation, medicalValidation);
      return !(
        this.pathologistState === false ||
        ((technicalValidation || medicalValidation) && this.resultState === false)
      );
    },
    activateValidations(technicalValidation = false, medicalValidation = false) {
      this.pathologistValidation = true;
      this.resultValidation = technicalValidation || medicalValidation;
    },
    hideValidations() {
      this.pathologistValidation = false;
      this.resultValidation = false;
    },

    // Actions
    async onSave(handleSuccess = true, handleErrors = true, handleInvalidateTechnically = true) {
      try {
        // if cytotechnicianId has changed, the report is edited
        if (!this.cytotechnician || this.cytotechnician.id !== this.$userSettings.user.id) {
          this.edited = true;
        }
        // no change (except for invalidate technically) : do nothing
        if (!this.edited) {
          if (!this.validatedTechnically || !handleInvalidateTechnically) {
            if (handleSuccess) {
              this.$emit("alert", "success", {
                title: this.$t("medicalReportEdit.notifications.editionSuccessTitle"),
                message: this.$t("medicalReportEdit.notifications.editionSuccessText")
              });
              const refresh = await this.refreshReportByFileId(this.fileId);
              return refresh.imported;
            }
            return true;
          }
        }

        this.isLoading = true;

        // validate before saving
        if (!this.validateForm(false, false)) {
          this.isLoading = false;
          return false;
        }

        const form = this.form;
        form.subquality = this.subquality;
        form.pathologistId = this.pathologist.id;
        form.fileId = this.fileId;
        form.hpvResultIds = this.hpvResults.map((item) => { return item.id; });

        if (this.cytologyReportData) {
          // edition save
          const res = await commonServices.put("cytologyReports", form, this.cytologyReportData.id);
          if (res.data === true) {
            if (handleSuccess) {
              this.$emit("alert", "success", {
                title: this.$t("medicalReportEdit.notifications.editionSuccessTitle"),
                message: this.$t("medicalReportEdit.notifications.editionSuccessText")
              });
              const refresh = await this.refreshReportByFileId(this.fileId);
              return refresh.imported;
            } else {
              this.isLoading = false;
              return true;
            }
          } else if (res.data.error && res.data.error === "alreadyValidatedMedicallyOrCancelled") {
            this.$emit("alert", "danger", {
              title: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledTitle"),
              message: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledText")
            });
            this.isLoading = false;
            await this.refreshCytologyReports();
            return false;
          } else {
            if (handleErrors) {
              this.$emit("alert", "danger", {
                title: this.$t("medicalReportEdit.notifications.editionErrorTitle"),
                message: this.$t("medicalReportEdit.notifications.editionErrorText")
              });
            }
            this.isLoading = false;
            return false;
          }
        } else {
          // addition save
          const res = await commonServices.post("cytologyReports", form);
          if (res.data.id) {
            if (handleSuccess) {
              this.$emit("alert", "success", {
                title: this.$t("medicalReportEdit.notifications.additionSuccessTitle"),
                message: this.$t("medicalReportEdit.notifications.additionSuccessText")
              });
              const refresh = await this.refreshReportByFileId(this.fileId);
              this.isLoading = false;
              return refresh.imported;
            } else {
              this.cytologyReportId = res.data.id;
              this.isLoading = false;
              return true;
            }
          } else if (res.data.error && res.data.error === "alreadyCreated") {
            this.$bvModal.show("alreadyCreatedModal-" + this._uid);
            this.isLoading = false;
            return false;
          } else {
            if (handleErrors) {
              this.$emit("alert", "danger", {
                title: this.$t("medicalReportEdit.notifications.additionErrorTitle"),
                message: this.$t("medicalReportEdit.notifications.additionErrorText")
              });
            }
            this.isLoading = false;
            return false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onValidate(validationConfirmation = true) {
      // validate rights
      if (this.isAdmin) {
        this.$emit("alert", "danger", {
          title: this.$t("medicalReportEdit.validations.validationUserRightsErrorTitle"),
          message: this.$t("medicalReportEdit.validations.validationUserRightsErrorText")
        });
        return false;
      }
      // validate hpv without result = error
      if (this.isHpv && this.hpvResults.length === 0) {
        this.$emit("alert", "danger", {
          title: this.$t("medicalReportEdit.validations.validationNoHpvResultErrorTitle"),
          message: this.$t("medicalReportEdit.validations.validationNoHpvResultErrorText")
        });
        return false;
      }

      // no change and validated to the maximum = success
      if (!this.edited) {
        if (this.isCytotechnician && this.validatedTechnically) {
          this.$emit("alert", "success", {
            title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
            message: this.$t("medicalReportEdit.notifications.validationSuccessText")
          });
          this.navigateBack();
          return true;
        } else if (this.isPathologist && this.validatedMedically) {
          this.$emit("alert", "success", {
            title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
            message: this.$t("medicalReportEdit.notifications.validationSuccessText")
          });
          return true;
        }
      }

      this.isLoading = true;

      // validate fields
      if (!this.validateForm(true, this.isPathologist)) {
        this.isLoading = false;
        return false;
      }

      let successSave = false;
      let successTechValidation = false;
      let successMedValidation = false;

      // save
      if (this.edited) {
        // save changes
        successSave = await this.onSave(false, true, false);
        if (!successSave) {
          this.isLoading = false;
          return false;
        }
      }

      this.isLoading = true;
      // validationConfirmation
      if (validationConfirmation && !this.parentFileId && this.isPathologist) {
        this.$bvModal.show("validationConfirmationModal");
        if (successSave) {
          const refresh = await this.refreshReportByFileId(this.fileId);
          this.isLoading = false;
          return refresh.imported;
        } else {
          this.isLoading = false;
          return true;
        }
      } else {
        this.$bvModal.hide("validationConfirmationModal");
      }

      this.isLoading = true;
      // validate technically
      if (!this.validatedTechnically) {
        // send request
        successTechValidation = await this.onValidateTechnically(false);
        if (!successTechValidation) {
          if (successSave) await this.refreshReportByFileId(this.fileId);
          this.isLoading = false;
          return false;
        }
      }

      this.isLoading = true;
      if (this.isPathologist && !this.validatedMedically) {
        // send request
        successMedValidation = await this.onValidateMedically(true);
        if (successSave || successTechValidation || successMedValidation) {
          await this.refreshReportByFileId(this.fileId);
        }
        this.isLoading = false;
        return successMedValidation;
      } else {
        // don't need to validate medically : end of function
        this.$emit("alert", "success", {
          title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
          message: this.$t("medicalReportEdit.notifications.validationSuccessText")
        });
        if (this.isPathologist) {
          if (successSave || successTechValidation) await this.refreshReportByFileId(this.fileId);
        } else {
          this.navigateBack();
        }
        this.isLoading = false;
        return true;
      }
    },
    async onValidateTechnically(displaySuccess = true) {
      try {
        const res = await cytologyReportsServices.validateTechnically(this.fileId);
        if (res.data === true) {
          if (displaySuccess) {
            this.$emit("alert", "success", {
              title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
              message: this.$t("medicalReportEdit.notifications.validationSuccessText")
            });
            this.navigateBack();
          }
          this.isLoading = false;
          return true;
        } else if (res.data.error === "fileNotValidatedForDiagnosis") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisText")
          });
        } else if (res.data.error && res.data.error === "alreadyValidatedTechnicallyOrCancelled") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.alreadyValidatedTechnicallyOrCancelledTitle"),
            message: this.$t("medicalReportEdit.notifications.alreadyValidatedTechnicallyOrCancelledText")
          });
          this.isLoading = false;
          await this.refreshCytologyReports();
          return false;
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.validationErrorTitle"),
            message: this.$t("medicalReportEdit.notifications.validationErrorText")
          });
        }
        this.isLoading = false;
        return false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onValidateMedically(displaySuccess = true) {
      try {
        const res = await cytologyReportsServices.validateMedically(this.fileId);
        if (res.data === true) {
          if (displaySuccess) {
            this.$emit("alert", "success", {
              title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
              message: this.$t("medicalReportEdit.notifications.validationSuccessText")
            });
          }
          this.isLoading = false;
          return true;
        } else if (res.data.error === "fileNotValidatedForDiagnosis") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisText")
          });
        } else if (res.data.error === "fileNotValidatedTechnically") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotValidatedTechnicallyTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotValidatedTechnicallyText")
          });
        } else if (res.data.error === "fileNotSent") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotSentTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotSentText")
          });
        } else if (res.data.error && res.data.error === "alreadyValidatedMedicallyOrCancelled") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledTitle"),
            message: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.validationErrorTitle"),
            message: this.$t("medicalReportEdit.notifications.validationErrorText")
          });
        }
        this.isLoading = false;
        await this.refreshCytologyReports();
        return false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onView() {
      if (!this.isDownloading) {
        this.isDownloading = true;
        try {
          const resFile = await commonServices.export("cytologyReports", this.cytologyReportData.id, "pdf");
          if (resFile.data.type === "application/json") {
            this.$emit("alert", "danger", {
              title: this.$t("general.downloadFileErrorTitle"),
              message: this.$t("general.downloadFileErrorText")
            });
          } else {
            fileSaver.saveAs(resFile.data, this.fileData.accessNumber + ".pdf");
          }
        } catch (err) {
          this.handleErrors(err);
        }
        this.isDownloading = false;
      }
    },

    // cancel and go back
    navigateBack() {
      if (this.cancelRouteProp) {
        navigate(this.cancelRouteProp);
      } else if (this.isAdmin) {
        navigate("diagnosis/files");
      } else if (this.isPathologist) {
        navigate("diagnosis/validation/cytologyValidation");
      } else if (this.isCytotechnician) {
        navigate("diagnosis/medicalReports/cytologyReports");
      }
    }
  }
};
</script>

<style src="./CytologyReportEdit-global.scss" lang="scss"></style>
<style scoped src="./CytologyReportEdit.scss" lang="scss"></style>
